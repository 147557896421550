import { bgColors } from 'backgammon_ui_shared';
import styled, { keyframes, css } from 'styled-components';

export const Overlay = styled.div({
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  position: 'fixed',
  opacity: 0.6,
  background: bgColors.darkSpace,
});

export const OverlayInner = styled.div({
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
});

export const OverlayContent = styled.div({
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.5);
  border-top-color: #eee;
  animation: ${css`
      ${spin}`} 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
  margin-bottom: 10px;
`;
