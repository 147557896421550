import { BoxStyled } from 'backgammon_ui_shared';
import styled, { CSSObject } from 'styled-components';

type Props = {
  gradient: string[];
  isSelected: boolean;
  isLocked: boolean;
  isTabOrScreen: boolean;
};

type Dimensions = {
  isTabOrScreen: boolean;
};

export const GameLevelCard = styled(BoxStyled)<Props>`
  flex: 0 0 30%;
  background: ${(props: Props) => `linear-gradient(to right, ${props.gradient[0]}, ${props.gradient[1]})`};
  cursor: ${({ isLocked }: Props) => (isLocked ? `default` : 'pointer')};
  opacity: ${({ isLocked }: Props) => (isLocked ? `50%` : undefined)};
`;

export const CoinIcon = styled.img<Dimensions>(
  ({ isTabOrScreen }: Dimensions): CSSObject => ({
    width: isTabOrScreen ? '28px' : '20px',
    height: isTabOrScreen ? '28px' : '20px',
    marginRight: '5px',
  }),
);
