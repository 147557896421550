import React from 'react';
import { Overlay, OverlayContent, OverlayInner, Spinner } from './styles';
import { TextStyled } from 'backgammon_ui_shared';

const Loader = () => {
  return (
    <Overlay>
      <OverlayInner>
        <OverlayContent>
          <Spinner />
          <TextStyled>Creating the match</TextStyled>
        </OverlayContent>
      </OverlayInner>
    </Overlay>
  );
};

export default Loader;
