import { useResponsiveBooleansContext } from 'backgammon_ui_shared';
import React from 'react';
import { BetaRibbon, MenuItemButton, MenuItemIcon, MenuItemTitle } from './SideMenuItem.styles';

interface Props {
  dataLabel?: string;
  iconPath: string;
  onClick?(): void;
  disabled?: boolean;
  isBeta?: boolean;
}

function SideMenuItem({ iconPath, onClick, children, disabled, dataLabel, isBeta }: React.PropsWithChildren<Props>) {
  const { isTabOrScreen } = useResponsiveBooleansContext();
  return (
    <MenuItemButton isTabOrScreen={isTabOrScreen} disabled={disabled} onClick={onClick} data-cy={dataLabel}>
      <MenuItemIcon isTabOrScreen={isTabOrScreen} iconPath={iconPath} />
      <MenuItemTitle isTabOrScreen={isTabOrScreen}>{children}</MenuItemTitle>
      {isBeta ? <BetaRibbon>BETA</BetaRibbon> : null}
    </MenuItemButton>
  );
}

export default SideMenuItem;
