import { bgColors, radialGradient } from 'backgammon_ui_shared';
import styled, { CSSProperties, createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  #root {
    width: 100%;
    height: 100vh;
  }
  .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover {
    border: 0 !important;
    border-bottom: 0 !important;
  }
  .MuiNativeSelect-icon {
    font-size: 40px !important;
    top: calc(50% - 20px) !important;
    color: #070024 !important;
  }
`;

const containerStyles: CSSProperties = {
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
};

export const Container = styled.div({
  width: '100vw',
  fontFamily: 'Roboto',
  background: bgColors.darkSpace,
  ...containerStyles,
});

export const ScreenContainer = styled.div({
  ...radialGradient('circle at 50% 100%', 'rgb(65, 12, 149)', '0%', 'rgb(7, 0, 36)', '35%'),
  maxWidth: '1280px',
  overflow: 'hidden',
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
});
